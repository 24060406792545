body {
  margin: 0;
  font-family: Consolas, "Courier New", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #110022;
  margin: 0 25%;
}

html,
body,
#app,
#app > div {
  height: 100%;
}
h1,
span,
p {
  margin: 0;
  white-space: pre;
}
#root {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  color: inherit;
}
